
























































































































































































































	import { Component } from 'vue-property-decorator';
	import { IFilterEvaluation } from '@/model/evaluation/IFilterEvaluation';
	import { IAudioData } from '@/model/agent/IAudioData';
	import { internet } from '@/utils/Internet';

	import Util from '@/utils/Util';
	import { IDataTable } from '@/model/main/IDataTable';
	import { IHeaderTable } from '@/model/main/IHeaderTable';
	import EvaluationDataTable from '@/components/evaluation/EvaluationDataTable.vue';
	import MixinMain from '@/mixing/MixinMain.vue';
	import AppContainerBase from '@/components/util/AppContainerBase.vue';
	import { ISendData } from '@/model/evaluation/ISendData';
	import { Icon } from '@/model/util/Icon';
	import domtoimage from "dom-to-image";
	import jsPDF from "jspdf"
	import { IFilterAudio } from '@/model/agent/IFilterAudio';

	@Component({
		name: 'ResultEvaluation',
		components: {
			EvaluationDataTable,
			AppContainerBase,
		},
	})
	export default class ResultEvaluation extends MixinMain {
		public filterAudio: IFilterEvaluation = {
			customer: '',
			campaing: '',
			agent: '',
			callType: '',
			subCategory: '',
			audio: '',
			ruta: ''
		};
		//public AudioRuta: any;
		public isLoading = false;
		public rows: Array<IDataTable> = [];
		public header: Array<IHeaderTable<IDataTable>> = [];
		public customer = '';
		public titleText = this.$t("ResultEvalText.title");
		public backToText = this.$t("ResultEvalText.textBackTo");
		public saveText = this.$t("ResultEvalText.textSave");
		public DownloadText = this.$t("ResultEvalText.textDownload");
		public items = [
			this.$t("ResultEvalText.item0"),
			this.$t("ResultEvalText.item1"),
			this.$t("ResultEvalText.item2"),
			this.$t("ResultEvalText.item3"),
			this.$t("ResultEvalText.item4"),
			this.$t("ResultEvalText.item5"),
			this.$t("ResultEvalText.item6"),
			this.$t("ResultEvalText.item7"),
			this.$t("ResultEvalText.item8"),
		];
		public tas = 0;
		public ruta = ''
		public audio_path = "";
		public token = localStorage.getItem('TOKEN')
		public dateSelected = '';
		public previousView = '';
		public evaluator = '';
		public dialogEditElex = false;
		public editElem: IDataTable = {};
		public newElem = "";
		public updateEditElex = 0;
		mounted(): void {
			this.audio_path = process.env.VUE_APP_API;
			console.log(this.audio_path);
			this.evaluator = this.$store.state.storeLogin.user.name;
			this.filterAudio = JSON.parse(
				this.$route.query.filter as string
			)
			this.getData();
		}

		get getDate(): string {
			return new Date().toISOString().slice(0, 10);
		}


		private getData(): void {
			this.isLoading = true;
			const request_1 = internet
				.newRequest()
				// .get(`xentric/agente/audiosScript?FiltrAgent=${this.filterAudio.agent}&${this.getFilterUrl}`);
				.get(`xentric/agente/oneAudioScript?AudioNombre=${this.filterAudio.audio}`);

			Util.waitForPromises([request_1])
				.then((responses) => {
					console.log(responses);
					const data: Array<IAudioData> = responses[0]
						.data as Array<IAudioData>;
					// const filter: Array<IAudioData> = data.filter(
					// 	(e) => {
					// 		return (
					// 			// e.agente ===
					// 			// 	this.filterAudio.agent &&
					// 			// e.campania ===
					// 			// 	this.filterAudio
					// 			// 		.campaing &&
					// 			e.tipo_llamada ===
					// 				this.filterAudio.callType.toLowerCase() &&
					// 			e.audio ===
					// 				this.filterAudio.audio /*&&
					// 			e.AudioRuta ===
					// 				this.filterAudio.ruta*/
					// 			//console.log(this.AudioRuta)	
					// 		);			
					// 	}
					// );
					// console.log(filter);

				//
				//	this.ruta = filter[0].AudioRuta;
					//this.filterAudio.AudioRuta = this.AudioRuta;
				//	console.log(this.AudioRuta)
					// SE COMENTA PARA NO REASIGNARLO
					// this.filterAudio.ruta = data[0].AudioRuta;
					this.customer = data[0].cliente;
					this.dateSelected = data[0].FechaAnalisis;
					let puntajeObtenido = 0;
					let puntajeTotal = 0;
					const dataTable: Array<IDataTable> = [];
					for (let [index, item] of data.entries()) {
						const row: IDataTable = {};
						row['id'] = item.id_sub;
						row['cat'] = item.categoria;
						row['sub'] = item.subcategoria;
						row['robot'] = item.encontrado;
						row['eval'] = '';
						row['tun'] = '';
						row['extra'] = '';
						row['elex'] = item.elemento_explicito;
						row['active'] = false;
						row['reg'] = item._id;
						row['nro'] = item.MT_Folio;
						row['scr'] = item.IDScript;
						dataTable.push(row);
						puntajeObtenido = puntajeObtenido + (item.puntaje*item.encontrado);
						puntajeTotal = puntajeTotal + item.puntaje;
					}
					this.tas = parseFloat(
						((puntajeObtenido * 100) / puntajeTotal).toFixed(1)
					);
					const header: Array<IHeaderTable<
						IDataTable
					>> = Object.keys(dataTable[0]).map(
						(
							key: string
						): IHeaderTable<IDataTable> => {
							let text = key;
							switch (key) {
								case 'id':
									text = '#';
									break;
								case 'cat':
									text = this.$t("ResultEvalText.tableCat") as string;
									break;
								case 'sub':
									text = this.$t("ResultEvalText.tableSubcat") as string;
									break;
								case 'robot':
									text = this.$t("ResultEvalText.tableBot") as string;
									break;
								case 'eval':
									text = this.$t("ResultEvalText.tableEval") as string;
									break;
								case 'tun':
									text = this.$t("ResultEvalText.tableTun") as string;
									break;
							}
							return {
								text,
								value: key,
							};
						}
					) as Array<IHeaderTable<IDataTable>>;
					this.rows = dataTable;
					this.header = header;
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}
		public back() {
			console.log("PREV PAGE: ", this.$route.params.prev);
			if(this.$route.params.prev === 'agent'){
				let agent = this.$route.params.agent;
				let portfolio = this.$route.params.portfolio;
				//console.log('PORTFOLIO: ', portfolio);
				this.$router.push({
					name: 'Agent',
					params: {
						agent,
						portfolio,
					},
				});
			}else if(this.$route.params.prev === 'buscar'){
				this.$router.push({
					name:'MainTranscription'
				});
			}else{
				this.$router.push({
					name:'MainEvaluation'
				});
			}
		}

		public save() {
			this.isLoading = true;
			let error = false;
			const send: Array<ISendData> = [];
			for (let row of this.rows) {
				let data: ISendData = {};
				data['NRO_evaluacion'] = row.nro;
				data['agente'] = this.filterAudio.agent;
				data['audio'] = this.filterAudio.audio;
				data['categoria'] = row.cat;
				data['cliente'] = this.customer;
				data['codigo_script'] = row.scr;
				data['evaluacion_robot'] = row.robot;
				if (row.eval === '') {
					continue;
				}
				data['evaluacion_usuario'] = row.eval;
				data[
					'fecha_evaluacion'
				] = new Date().toLocaleString();
				data['fecha_llamada'] = this.dateSelected;
				data['id_registro'] = row.reg;
				data['revision_evaluacion'] = 'pendiente';
				data['subcategoria'] = row.sub;
				data['tas'] = this.tas.toFixed(1);
				data['tipo_llamada'] = this.filterAudio.callType;
				// if (row.extra === '') {
				// 	error = true;
				// 	break;
				// }
				data['tunning'] = `${row.tun} ${row.extra}`;
				data['unNegocio'] = this.filterAudio.campaing;
				data['usuario_evaluador'] = this.evaluator;
				send.push(data);
			}
			console.log(error, send);

			if (error) {
				Util.showMessage(
					this.$t("DataTable.warningTunning") as string,
					Icon.INFO,
					this.$t("DataTable.cuidado") as string
				);
				this.isLoading = false;
				return;
			}
			const requests = [];
			for (let reg of send) {
				const request = internet
					.newRequest()
					.post('insertEvaluacion/agente', reg);
				requests.push(request);
			}
			Util.waitForPromises(requests)
				.then((responses) => {
					Util.showMessage(
						this.$t("DataTable.cambiosGuardados") as string,
						Icon.SUCCESS,
						this.$t("DataTable.avisoExito") as string,
					);
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
				});
		}

		public downloadWithCSS() {
            domtoimage
            .toPng(document.querySelector('#print')!)
            .then(function(dataUrl: any) {
                var img = new Image();
                img.src = dataUrl;
                img.onload = function () {
                    var doc :any =''
					//console.log("img:", img.width,  img.height)
                    if (img.width > img.height) {
                        doc = new jsPDF('l', 'mm', [img.width, img.height])
                    } else {
                        doc = new jsPDF('p', 'mm', [img.width, img.height])
                    }          
                    doc.addImage (img, 'jpeg', 10, 10, img.width, img.height) // La relación se puede ajustar según sea necesario
                    const date = new Date();
                    const filename =
                        "dashboard - " +
                        date.getFullYear() +
                        ("0" + (date.getMonth() + 1)).slice(-2) +
                        ("0" + date.getDate()).slice(-2) +
                        ("0" + date.getHours()).slice(-2) +
                        ("0" + date.getMinutes()).slice(-2) +
                        ("0" + date.getSeconds()).slice(-2) +
                        ".pdf";
                    doc.save(filename);
                };
            })
            .catch(function(error: any) {
                console.error("oops, something went wrong!", error);
            });
        }

		public descargarAudio(ruta:string, nombre:string) {
			// Crear un enlace temporal
			const link = document.createElement("a");

			// Establecer la URL del enlace a la ruta del archivo de audio
			// link.href = `${this.audio_path}/rutaAudioSftp/?token=${this.token}&amp;ruta=${this.filterAudio.ruta}${this.filterAudio.audio}`;
			link.href = ruta;

			// Establecer el atributo download con el nombre deseado
			// link.download = "descarga.mp3"; // Puedes personalizar el nombre aquí
			link.download = nombre;

			// Agregar el enlace al DOM
			document.body.appendChild(link);

			// Simular un clic en el enlace para iniciar la descarga
			link.click();

			// Eliminar el enlace del DOM después de la descarga
			document.body.removeChild(link);
		}

		public handleEditElex(data: IDataTable) {
			console.log("Item llego a View: ", data);
			this.editElem = data;
			this.dialogEditElex = true;
		}

		public deleteElem(data: IDataTable, elemento: string, index: number) {
			console.log(data, elemento);
			data.elexList.splice(index, 1);
			this.updateEditElex += 1;
		}
		
		public updateElem(data: IDataTable, elemento: string, index: number) {
			console.log(data, elemento);
			data.elexList.splice(index, 1);
			this.newElem = elemento;
			this.updateEditElex += 1;
		}

		public addElem(data: IDataTable, elemento: string) {
			console.log(data, elemento);
			data.elexList.push(this.newElem);
			this.newElem = "";
			this.updateEditElex += 1;
		}

		public sendEditElem() {
			let data = this.editElem;
			const request = internet
				.newRequest()
				.post('editElementos/agente', data);
			
			Util.waitForPromises([request])
				.then((responses) => {
					this.dialogEditElex = false;
					this.editElem = {};
					Util.showMessage(
						this.$t("DataTable.cambiosGuardados") as string,
						Icon.SUCCESS,
						this.$t("DataTable.avisoExito") as string,
					);
				})
				.catch(console.log)
		}
	}
